import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetSystemRes, GetSystemListParameters, GetDynamicFieldTemplateListParameters, GetDynamicFieldListRes, GetDynamicFieldTemplateListRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import SystemEditAndAdd from './SystemAdd';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import { ActionUserRes } from '../../actions/ActionUser';
import moment from 'moment';

const { confirm } = Modal;

type EditAndAddModalType = { type: 'add' } | { type: 'edit', id: string }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  dynamicFieldTemplateList: GetDynamicFieldTemplateListRes['data']
}
interface Props {
}


interface Page {
  tableRef?: JJ_Table<GetSystemRes> | null
}


type PageProps = Props & RouteChildrenProps & ConnectedProps<typeof connector>

interface RootState {
  user: ActionUserRes
}

const mapState = (state: RootState) => ({
  user: state.user,

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class SystemList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      dynamicFieldTemplateList: [],
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetSystemRes> | null
  componentDidMount() {
    this._initData()
  }


  _getDynamicFieldTemplateList = async (params: GetDynamicFieldTemplateListParameters) => {
    const res = await api.GetDynamicFieldTemplateList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })

      const dynamicFieldTemplateListRes = await this._getDynamicFieldTemplateList({ count: 1000, type: 'System' })
      this.setState({
        isSpinLoading: false,
        dynamicFieldTemplateList: dynamicFieldTemplateListRes.data,
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {

    return (
      <div id="SystemList"
      >
        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit' ? '編輯系統' : '新建系統'}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <SystemEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`編輯成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetSystemRes, {
              desc: 'ascend' | 'descend'
              status: 'TEST' | 'NORMAL'
              dynamicFieldTemplateId?: string
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '任意模板',
                  allowClear: true,
                  key: 'dynamicFieldTemplateId',
                  options: this.state.dynamicFieldTemplateList.map(item => ({
                    value: item.id,
                    name: item.name,
                    disabled: false,
                  })),
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'statistics',
                            name: '查看統計',
                            onAction: () => {
                              // this.props.history.push(`/dynamic-model/statistics?dynamicFieldTemplateId=${record.dynamicFieldTemplateId}&codes=["${record.code}"]&dynamicFieldKeys=["CpuTemperature","CpuUsageRate"]`)
                              window.open(`#/dynamic-model/statistics?dynamicFieldTemplateId=${record.dynamicFieldTemplateId}&codes=["${record.code}"]&dynamicFieldKeys=["CpuTemperature","CpuUsageRate"]&collapsed=true`, '_blank')

                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否刪除${record.name}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteSystem({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.name} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.name} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },

                {
                  title: '名稱',
                  dataIndex: 'name',
                  key: 'name',
                  width: 200,
                },
                {
                  title: '字段模板',
                  dataIndex: 'dynamicFieldTemplateId',
                  key: 'dynamicFieldTemplateId',
                  render: (value, record) => {
                    const dft = this.state.dynamicFieldTemplateList.find(item => item.id === value)
                    return dft?.name
                  }
                },
                {
                  title: '代碼',
                  dataIndex: 'code',
                  key: 'code',
                },

                {
                  title: '更新時間',
                  dataIndex: 'updatedAt',
                  key: 'updatedAt',
                  render: (value) => {

                    //  获取当前时间
                    const now = moment();
                    // 获取另一个时间，例如某个特定的日期和时间
                    const otherTime = moment(value);

                    // 计算两个时间之间的差异
                    const duration = moment.duration(now.diff(otherTime));

                    // 获取小时数
                    const hours = duration.asHours();
                    // 判断是否大于24小时
                    let isRed = hours >= 24 || !value


                    return (
                      <JJ_TableItemMoment
                        date={value}
                        style={{ color: isRed ? 'red' : undefined }}
                      />
                    )
                  }
                },

                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'系統列表'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetSystemListParameters['isAscend']
                  dynamicFieldTemplateId?: GetSystemListParameters['dynamicFieldTemplateId']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  params.dynamicFieldTemplateId = sourceItemBody.dynamicFieldTemplateId
                }
                const res = await api.GetSystemList({
                  ...body,
                  ...params,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(SystemList)

