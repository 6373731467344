import React, { } from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import Moment from 'react-moment';
import { connect, ConnectedProps } from 'react-redux';
import { ActionConfigRes } from '../actions/ActionConfig';

interface PageState {

}

interface Props {
  date: string
  locale?: 'zh-tw' | 'en' | 'zh-cn'
  style?: React.CSSProperties | undefined
}

type PageProps = Props & ConnectedProps<typeof connector>

interface Page {

}






interface RootState {
  config: ActionConfigRes
}

const mapState = (state: RootState) => ({
  config: state.config
})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class JJ_TableItemMoment extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  _getLocale = (locale: PageProps['locale']) => {
    switch (locale) {
      case 'en': {
        return undefined
      }
      case 'zh-cn': {
        return 'zh-cn'
      }
      case 'zh-tw': {
        return 'zh-tw'
      }
      default: return 'zh-tw'
    }
  }

  render() {

    if (this.props.date) {

      switch (this.props.config.momentType) {
        case 'format': {
          return (
            <Tooltip title={moment(this.props.date).fromNow()}>
              <Moment style={this.props.style} date={this.props.date} locale={this._getLocale(this.props.locale)} format={'YYYY-MM-DD HH:mm:ss'} />
            </Tooltip>
          )
        }
        case 'fromNow': {
          return (
            <Tooltip title={moment(this.props.date).format('YYYY-MM-DD HH:mm:ss')}>
              <Moment style={this.props.style} date={this.props.date} locale={this._getLocale(this.props.locale)} fromNow />
            </Tooltip>
          )
        }
        default: return (
          <Tooltip title={moment(this.props.date).format('YYYY-MM-DD HH:mm:ss')}>
            <Moment style={this.props.style} date={this.props.date} locale={this._getLocale(this.props.locale)} fromNow />
          </Tooltip>
        )
      }



    } else {
      return <div style={this.props.style} >--</div>
    }

  }

}
export default connector(JJ_TableItemMoment)
