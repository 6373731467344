
import { ApiConfig, ApiConfigType } from "./api-config"
import * as Result from "./api-result"
import * as Parameters from './api-parameters-type'
import * as Res from './api-res-type'
import { AxiosInstance } from 'axios'
import { createHttp } from './http';

export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor (config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }


  async PostAuthPasswordLogin(params: Parameters.PostAuthPasswordLoginParameters): Promise<Result.PostAuthPasswordLoginResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/login`, params)
      const data: Res.PostAuthPasswordLoginRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PostAuthPasswordChange(params: Parameters.PostAuthPasswordChangeParameters): Promise<Result.PostAuthPasswordChangeResult> {
    try {
      const res = await this.http.post(`/admin/auth/password/change`, params)
      const data: Res.PostAuthPasswordChangeRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async DeleteAuth(): Promise<void> {
    await this.http.delete(`/admin/auth`)
    return
  }


  // ================= User =================


  async GetUser(): Promise<Result.GetUserResult> {
    try {
      const res = await this.http.get(`/admin/user/${'me'}`)
      const data: Res.GetUserRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }




  // ================= System =================

  async GetSystemList(params: Parameters.GetSystemListParameters): Promise<Result.GetSystemListResult> {
    try {
      const res = await this.http.get(`/admin/system`, { params })
      const data: Res.GetSystemListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetSystem(params: Parameters.GetSystemParameters): Promise<Result.GetSystemResult> {
    try {
      const res = await this.http.get(`/admin/system/${params.id}`, { params })
      const data: Res.GetSystemRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutSystem(params: Parameters.PutSystemParameters): Promise<Result.PutSystemResult> {
    try {
      const res = await this.http.put(`/admin/system/${params.id}`, params)
      const data: Res.PutSystemRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteSystem(params: Parameters.DeleteSystemParameters): Promise<Result.DeleteSystemResult> {
    try {
      const res = await this.http.delete(`/admin/system/${params.id}`, { params })
      const data: Res.DeleteSystemRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  // ================= Project =================

  async GetProjectList(params: Parameters.GetProjectListParameters): Promise<Result.GetProjectListResult> {
    try {
      const res = await this.http.get(`/admin/project`, { params })
      const data: Res.GetProjectListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetProject(params: Parameters.GetProjectParameters): Promise<Result.GetProjectResult> {
    try {
      const res = await this.http.get(`/admin/project/${params.id}`, { params })
      const data: Res.GetProjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }

  async PutProject(params: Parameters.PutProjectParameters): Promise<Result.PutProjectResult> {
    try {
      const res = await this.http.put(`/admin/project/${params.id}`, params)
      const data: Res.PutProjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteProject(params: Parameters.DeleteProjectParameters): Promise<Result.DeleteProjectResult> {
    try {
      const res = await this.http.delete(`/admin/project/${params.id}`, { params })
      const data: Res.DeleteProjectRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  // ================= DynamicFieldTemplate =================

  async GetDynamicFieldTemplateList(params: Parameters.GetDynamicFieldTemplateListParameters): Promise<Result.GetDynamicFieldTemplateListResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-field-template`, { params })
      const data: Res.GetDynamicFieldTemplateListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetDynamicFieldTemplate(params: Parameters.GetDynamicFieldTemplateParameters): Promise<Result.GetDynamicFieldTemplateResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-field-template/${params.id}`, { params })
      const data: Res.GetDynamicFieldTemplateRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostDynamicFieldTemplate(params: Parameters.PostDynamicFieldTemplateParameters): Promise<Result.PostDynamicFieldTemplateResult> {
    try {
      const res = await this.http.post(`/admin/dynamic-field-template`, params)
      const data: Res.PostDynamicFieldTemplateRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutDynamicFieldTemplate(params: Parameters.PutDynamicFieldTemplateParameters): Promise<Result.PutDynamicFieldTemplateResult> {
    try {
      const res = await this.http.put(`/admin/dynamic-field-template/${params.id}`, params)
      const data: Res.PutDynamicFieldTemplateRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteDynamicFieldTemplate(params: Parameters.DeleteDynamicFieldTemplateParameters): Promise<Result.DeleteDynamicFieldTemplateResult> {
    try {
      const res = await this.http.delete(`/admin/dynamic-field-template/${params.id}`, { params })
      const data: Res.DeleteDynamicFieldTemplateRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  // ================= DynamicField =================

  async GetDynamicFieldList(params: Parameters.GetDynamicFieldListParameters): Promise<Result.GetDynamicFieldListResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-field`, { params })
      const data: Res.GetDynamicFieldListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetDynamicField(params: Parameters.GetDynamicFieldParameters): Promise<Result.GetDynamicFieldResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-field/${params.id}`, { params })
      const data: Res.GetDynamicFieldRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PostDynamicField(params: Parameters.PostDynamicFieldParameters): Promise<Result.PostDynamicFieldResult> {
    try {
      const res = await this.http.post(`/admin/dynamic-field`, params)
      const data: Res.PostDynamicFieldRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async PutDynamicField(params: Parameters.PutDynamicFieldParameters): Promise<Result.PutDynamicFieldResult> {
    try {
      const res = await this.http.put(`/admin/dynamic-field/${params.id}`, params)
      const data: Res.PutDynamicFieldRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async DeleteDynamicField(params: Parameters.DeleteDynamicFieldParameters): Promise<Result.DeleteDynamicFieldResult> {
    try {
      const res = await this.http.delete(`/admin/dynamic-field/${params.id}`, { params })
      const data: Res.DeleteDynamicFieldRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



  async GetDynamicFieldWarnSet(params: Parameters.GetDynamicFieldWarnSetParameters): Promise<Result.GetDynamicFieldWarnSetResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-field/${params.id}/warn-set`, { params })
      const data: Res.GetDynamicFieldWarnSetRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  async PutDynamicFieldWarnSet(params: Parameters.PutDynamicFieldWarnSetParameters): Promise<Result.PutDynamicFieldWarnSetResult> {
    try {
      const res = await this.http.put(`/admin/dynamic-field/${params.id}/warn-set`, params)
      const data: Res.PutDynamicFieldWarnSetRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }





  // ================= DynamicModel =================

  async GetDynamicModelList(params: Parameters.GetDynamicModelListParameters): Promise<Result.GetDynamicModelListResult> {
    try {
      const res = await this.http.get(`/admin/dynamic-model`, { params })
      const data: Res.GetDynamicModelListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }


  // ================= FieldWarn 字段警告 =================

  async GetFieldWarnList(params: Parameters.GetFieldWarnListParameters): Promise<Result.GetFieldWarnListResult> {
    try {
      const res = await this.http.get(`/admin/field-warn`, { params })
      const data: Res.GetFieldWarnListRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }
  async GetFieldWarn(params: Parameters.GetFieldWarnParameters): Promise<Result.GetFieldWarnResult> {
    try {
      const res = await this.http.get(`/admin/field-warn/${params.id}`, { params })
      const data: Res.GetFieldWarnRes = res.data
      return { kind: 'ok', data }
    } catch ({ response }) {
      const { data = {} } = response || {}
      const { errorCode = '' } = data
      return { kind: errorCode }
    }
  }



}


