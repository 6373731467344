import React, { ReactElement } from 'react';
import { Form, Input, Button, Spin, Divider } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import JJ_FromIcon from './JJ_FromIcon';
import { Rule, FormProps, FormInstance, FormItemProps } from 'antd/lib/form';
import JJ_FromI18nInputText from './JJ_FromI18nInputText';
import JJ_FromI18nInputTextByDefault from './JJ_FromI18nInputTextByDefault';
import JJ_FromI18nTextAreaByDefault from './JJ_FromI18nTextAreaByDefault';
export interface FormItemData {
  id: string
  name?: string | string[]
  label?: string
  rules?: Rule[]
  componet?: ReactElement
  extra?: React.ReactNode
  props?: FormItemProps
}






interface PageProps<FromValue> {
  formListData: FormItemData[]
  onLoadData: () => Promise<{}>
  onFinish(values: any): Promise<any>
  title?: string
  formProps?: FormProps<FromValue>
  formRef?: ((instance: FormInstance<FromValue> | null) => void)
}

interface PageState {
  loading: boolean
  fromKey: any
  initialValues: any
}

interface Page {
  _loadData: () => void
  _onFinish: (values: {}) => void
  _getFormItem: (itemData: FormItemData, index: number) => ReactElement
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a validate email!',
    number: 'Not a validate number!',
  },
  number: {
    range: 'Must be between ${min} and ${max}',
  },
};

export default class JJ_From<FromValue = any> extends React.Component<PageProps<FromValue>, PageState> implements Page {


  componentDidMount() {
    this._loadData()
  }

  componentWillUnmount() {
    this.setState = () => { }
  }

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      fromKey: Date.now(),
      initialValues: {}
    }
  }

  _getFormItem = (itemData: FormItemData, index: number) => {
    switch (itemData.id) {
      case 'avatar':
      case 'icon': {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            name={itemData.name || itemData.id}
            label={itemData.label || ''}
            rules={itemData.rules}
          >
            <JJ_FromIcon />
          </Form.Item>
        )
      }
      case 'submit': {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            wrapperCol={{ offset: 4 }}>
            <Button
              type="primary" htmlType="submit">
              {itemData.label || '保存'}
            </Button>
          </Form.Item>
        )
      }
      case 'list': {
        return (
          <Form.Item
            extra={itemData.extra}
            name={itemData.name}
            key={index}
            label={itemData.label || ''}
            rules={itemData.rules}

          >
            <Form.List name={itemData.name || ''}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => {
                      return (
                        <div
                          key={field.key}
                          style={{ marginBottom: 10, padding: 10, background: 'rgba(0,0,0,0.05)' }}
                        >
                          <Form.Item
                            {...field}
                            noStyle
                          >
                            {itemData.componet || <div />}
                          </Form.Item>

                          <Form.Item
                          >
                            <Button
                              type={'ghost'}

                              onClick={() => {
                                remove(field.name);
                              }}

                            >
                              <MinusCircleOutlined /> 刪除
                          </Button>
                          </Form.Item>

                        </div>
                      )
                    })}
                    <Form.Item
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add field
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

          </Form.Item>

        )
      }
      case 'listI18nText': {
        return (
          <Form.Item
            extra={itemData.extra}
            name={itemData.name}
            key={index}
            label={itemData.label || ''}
            rules={itemData.rules}

          >
            <Form.List name={itemData.name || ''}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => {
                      return (
                        <div
                          key={field.key}
                          style={{ marginBottom: 10, padding: 10, background: 'rgba(0,0,0,0.05)' }}
                        >
                          <h2>{field.name + 1}</h2>
                          <Form.Item
                            {...field}
                            rules={[
                              {
                                ...(itemData.rules
                                  ? itemData.rules[0]
                                    ? itemData.rules[0]
                                    : {}
                                  : {}),
                                message: undefined,
                                validator(rule, value) {
                                  if (rule.required) {
                                    if (!value) {
                                      return Promise.reject(`请输入${itemData.label} ${field.name + 1}`)
                                    }
                                    let errorTitle: string | undefined
                                    const title: any = {
                                      'en': 'English',
                                      'zh-tw': '繁體中文',
                                      'zh-cn': '简体中文',
                                    }
                                    Object.keys(value)
                                      .forEach(key => {
                                        if (!value[key] && !errorTitle) {
                                          errorTitle = title[key]
                                        }
                                      })

                                    return errorTitle
                                      ? Promise.reject(`请输入${itemData.label} 【${errorTitle}】`)
                                      : Promise.resolve()
                                  }
                                  return Promise.resolve()
                                },
                              }
                            ]}
                            noStyle
                          >
                            <JJ_FromI18nInputText />
                          </Form.Item>

                          <Button
                            style={{ marginTop: 10 }}
                            type={'ghost'}

                            onClick={() => {
                              remove(field.name);
                            }}

                          >
                            <MinusCircleOutlined /> 刪除
                          </Button>

                        </div>
                      )
                    })}
                    <Form.Item
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

          </Form.Item>

        )
      }


      case 'i18nText': {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            name={itemData.name || itemData.id}
            label={itemData.label || ''}
            rules={[
              {
                ...(itemData.rules
                  ? itemData.rules[0]
                    ? itemData.rules[0]
                    : {}
                  : {}),
                validator(rule, value) {
                  if (rule.required) {
                    if (!value) {
                      return Promise.reject(`请输入${itemData.label}`)
                    }
                    let errorTitle: string | undefined
                    const title: any = {
                      'en': 'English',
                      'zh-tw': '繁體中文',
                      'zh-cn': '简体中文',
                    }
                    Object.keys(value)
                      .forEach(key => {
                        if (!value[key] && !errorTitle) {
                          errorTitle = title[key]
                        }
                      })

                    return errorTitle
                      ? Promise.reject(`请输入${itemData.label} 【${errorTitle}】`)
                      : Promise.resolve()
                  }
                  return Promise.resolve()
                },
              }
            ]}
          >
            <JJ_FromI18nInputText />
          </Form.Item >
        )
      }

      case 'i18nAndDefaultText': {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            name={itemData.name || itemData.id}
            label={itemData.label || ''}
            rules={[
              {
                ...(itemData.rules
                  ? itemData.rules[0]
                    ? itemData.rules[0]
                    : {}
                  : {}),
                validator(rule, value) {
                  if (rule.required) {
                    if (!value) {
                      return Promise.reject(`请输入${itemData.label}`)
                    }
                    let errorTitle: string | undefined
                    const title: any = {
                      'default': '默認（必填）',
                    }
                    for (const key in value) {
                      if (!value[key] && !errorTitle) {
                        errorTitle = title[key]
                      }

                    }

                    return errorTitle
                      ? Promise.reject(`请输入${itemData.label} 【${errorTitle}】`)
                      : Promise.resolve()
                  }
                  return Promise.resolve()
                },
              }
            ]}
          >
            <JJ_FromI18nInputTextByDefault />
          </Form.Item >
        )
      }

      case 'i18nAndDefaultTextArea': {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            name={itemData.name || itemData.id}
            label={itemData.label || ''}
            rules={[
              {
                ...(itemData.rules
                  ? itemData.rules[0]
                    ? itemData.rules[0]
                    : {}
                  : {}),
                validator(rule, value) {
                  if (rule.required) {
                    if (!value) {
                      return Promise.reject(`请输入${itemData.label}`)
                    }
                    let errorTitle: string | undefined
                    const title: any = {
                      'default': '默認（必填）',
                    }
                    for (const key in value) {
                      if (!value[key] && !errorTitle) {
                        errorTitle = title[key]
                      }

                    }

                    return errorTitle
                      ? Promise.reject(`请输入${itemData.label} 【${errorTitle}】`)
                      : Promise.resolve()
                  }
                  return Promise.resolve()
                },
              }
            ]}
          >
            <JJ_FromI18nTextAreaByDefault />
          </Form.Item >
        )
      }

    

      case 'divider': {
        return (
          <Divider
            key={index}
          />
        )
      }

      default: {
        return (
          <Form.Item
            extra={itemData.extra}
            key={index}
            name={itemData.name || itemData.id}
            label={itemData.label || ''}
            rules={itemData.rules}
            {...itemData.props}
          >
            {itemData.componet || <Input />}
          </Form.Item>
        )
      }
    }
  }

  _onFinish = async (values: {}) => {
    try {
      this.setState({ loading: true, })
      await this.props.onFinish(values)
      this.setState({ loading: false, })
    } catch (error) {
      console.log('JJ_From=>_onFinish', error)

      this.setState({ loading: false, })
    }
  }

  _loadData = async () => {
    this.setState({ loading: true, })
    try {
      const data = await this.props.onLoadData()
      this.setState({
        loading: false,
        initialValues: data || {},
        fromKey: Date.now() + 1
      })

    } catch (error) {
      console.log('JJ_From=>_loadData', error)
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    return (
      <Spin spinning={this.state.loading}>
        {this.props.title && (<h1>{this.props.title}</h1>)}
        <Form<FromValue> {...layout}
          initialValues={this.state.initialValues}
          ref={(ref) => this.props.formRef && this.props.formRef(ref)}
          name="nest-messages"
          onFinish={this._onFinish}
          key={String(this.state.fromKey)}
          validateMessages={validateMessages}
          {...this.props.formProps}

        >
          {this.props.formListData.map((itemData, index) => this._getFormItem(itemData, index))}
        </Form>
      </Spin>
    )
  }

}
