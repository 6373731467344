import { RouteChildrenProps } from "react-router-dom"

export const pushParamsObjHistory = (paramsObj: { [x: string]: any | undefined }, history: RouteChildrenProps['history']) => {
    let paramsString = Object
        .keys(paramsObj)
        .filter(key => paramsObj[key])
        .map(key => {

            let value = paramsObj[key]

            if (typeof value === 'object') {
                value = JSON.stringify(value)
            }

            return (`${[key]}=${value}`)
        })
        .join('&')

    history.push(`?${paramsString}`)
}


/**
 * 过滤无效值
 */
export const filterParams = (obj: any) => {
    let newObj: any = {};
    for (const key in obj) {
        let data = obj[key]

        if (!(data === null || data === undefined)) {
            newObj[key] = data;
        }
    }
    return newObj;
}



/**
 * 獲取查詢字符串
 */
export const getQueryString = (obj: any) => {
    const paramsObject = filterParams(obj)


    const params = new URLSearchParams();

    Object.keys(paramsObject).forEach(key => {



        if (Array.isArray(paramsObject[key])) {
            // 使用key后面跟 "[]" 来指定数组
            paramsObject[key].forEach((value: any) => params.append(`${key}[]`, value));
        } else if (paramsObject[key]) {
            // 对于单个值，直接设置键值对
            params.set(key, paramsObject[key]);
        }

    });

    const queryString = params.toString();
    return queryString;
}